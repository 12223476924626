const settings = [
  {
    path: '/settings',
    name: 'settings.inventory',
    component: () => import('@/views/settings/InventorySettings.vue'),
    meta: {
      pageTitle: 'Configurações de Inventário',
      breadcrumb: [
        {
          text: 'Início',
          active: true,
        },
      ],
    },
  },
]

export default settings

import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'

import { BCardText } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import I2FormInputDate from '@/components/i2/forms/elements/I2FormInputDate.vue'
import I2FormInput from '@/components/i2/forms/elements/I2FormInput.vue'
import I2FormVueSelect from '@/components/i2/forms/elements/I2FormVueSelect.vue'
import I2Grid from '@/components/i2/grid/Grid.vue'
import I2SideBar from '@/components/i2/container/I2SideBar.vue'
import PageHeader from '@/components/i2/layout/PageHeader.vue'
import STextDivider from '@/components/i2/layout/TextDivider'
import SAutoComplete from '@/components/i2/forms/elements/SAutoComplete'
import I2FormTextarea from '@/components/i2/forms/elements/I2FormTextarea.vue'
import I2FormRadio from '@/components/i2/forms/elements/I2FormRadio.vue'
import I2FormCheckbox from '@/components/i2/forms/elements/I2FormCheckbox.vue'
import I2FormInputNumber from '@/components/i2/forms/elements/I2FormInputNumber'
import I2FormMultipleSelect from '@/components/i2/forms/elements/I2FormMultipleSelect.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(I2FormInputDate.name, I2FormInputDate)
Vue.component(I2FormInput.name, I2FormInput)
Vue.component(I2FormInputNumber.name, I2FormInputNumber)
Vue.component(I2FormVueSelect.name, I2FormVueSelect)
Vue.component(I2FormMultipleSelect.name, I2FormMultipleSelect)
Vue.component(I2Grid.name, I2Grid)
Vue.component(I2SideBar.name, I2SideBar)
Vue.component(PageHeader.name, PageHeader)
Vue.component(STextDivider.name, STextDivider)
Vue.component(SAutoComplete.name, SAutoComplete)
Vue.component(BCardText.name, BCardText)
Vue.component(I2FormTextarea.name, I2FormTextarea)
Vue.component(I2FormRadio.name, I2FormRadio)
Vue.component(I2FormCheckbox.name, I2FormCheckbox)
Vue.component('ValidationObserver', ValidationObserver)

const pages = [
  {
    path: '/pages',
    name: 'pages.list',
    component: () => import('@/views/pages/PagesList.vue'),
    meta: {
      pageTitle: 'Páginas',
      breadcrumb: [
        {
          text: 'Lista',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/:menuId/:pageId',
    name: 'page.render',
    component: () => import('@/views/pages/PageRender.vue'),
  },
]

export default pages

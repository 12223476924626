import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import password from '@/router/password'
import users from '@/router/users'
import clients from '@/router/clients'
import profiles from '@/router/profiles'
import pages from '@/router/pages'
import menus from '@/router/menus'
import settings from '@/router/settings'
import license from '@/router/license'
import station from '@/router/station'
import software from '@/router/software'
import websoftware from '@/router/websoftware'
import location from '@/router/location'
import dynamicfield from '@/router/dynamicfield'
import tags from '@/router/tags'
import stationfile from '@/router/stationfile'
        // :routes-imports://

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/LoginCenter.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/reports/:pageId/:reportId',
    name: 'report.show',
    component: () => import('@/views/reports/ReportPage.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    ...routes,
    ...password,
    ...users,
    ...clients,
    ...profiles,
    ...pages,
    ...menus,
    ...settings,
    ...license,
    ...station,
    ...software,
    ...websoftware,
    ...location,
    ...dynamicfield,
    ...tags,
...stationfile,
// :routes-unpack://
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'page-error-404' || to.name === 'login' || to.name === 'perdi-minha-senha'
        || to.name === 'recuperar-senha') {
    return next()
  }
  if (to.name && to.name.length) {
    if (!isUserLoggedIn() && to.name !== '/') {
      return false
    }
  }
  next()
  return true
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

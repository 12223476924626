const tags = [
  {
    path: '/tags',
    name: 'tags.list',
    component: () => import('@/views/tags/TagsList.vue'),
    meta: {
      pageTitle: 'Tags',
      breadcrumb: [
        {
          text: 'Lista',
          active: true,
        },
      ],
    },
  },
]

export default tags

<template>
  <b-form-group
      :label="label"
      :label-for="name"
  >
    <validation-provider
        #default="{ errors }"
        :name="label"
        :vid="name"
        :rules="rules"
    >
      <b-form-textarea
          :id="name"
          v-model="inputValue"
          :type="type"
          :debounce="delay"
          :state="errors.length > 0 ? false:null"
          :placeholder="placeholder"
          :readonly="readonly"
          :disabled="disabled"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>

</template>

<script>

import { BFormGroup, BFormTextarea } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required, min, minValue } from '@validations'

export default {
  name: 'I2FormTextarea',
  components: {
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    mask: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    delay: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    inputValue: '',
    errors: [],
    required,
    min,
    minValue,
  }),
  watch: {
    value(data) {
      this.inputValue = data
    },
    inputValue(val) {
      this.$emit('input', val)
    },
  },
  created() {
    this.inputValue = this.value
  },

}
</script>

<style scoped>

</style>

const license = [
  {
    path: '/licenses',
    name: 'licenses.list',
    meta: {
      pageTitle: 'Cadastro de Licenças',
      breadcrumb: [
        {
          text: 'Licença',
          active: true,
        },
      ],
    },
    component: () => import('@/views/license/LicenseList.vue'),
  },
  {
    path: '/licenses/:uuid/details',
    name: 'licenses.details',
    meta: {
      pageTitle: 'Informações da Licença',
      breadcrumb: [
        {
          text: 'Informações de Licença',
          active: true,
        },
      ],
    },
    component: () => import('@/views/license/LicenseDetails.vue'),
  },
]

export default license

<template>
  <validation-provider
    #default="{ errors }"
    :name="label"
    :vid="label"
    :rules="rules"
  >
    <b-form-group
      :label="label"
      :label-for="name"
    >
      <v-select
        :id="name"
        v-model="inputValue"
        :uid="name"
        :name="name"
        :placeholder="placeholder"
        label="text"
        :options="options"
        :state="errors.length > 0 ? false:null"
        :disabled="disabled"
        :loading="loading"
        :readonly="readonly"
        :filterable="false"
        @search="fetchOptions"
      >
        <div v-if="!noOptionMessage" slot="no-options">
          Nenhum(a) {{ label }} encontrado(a).
        </div>
        <div v-if="noOptionMessage" slot="no-options">
          {{ noOptionMessage }}
        </div>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider } from 'vee-validate'
import debounce from 'lodash/debounce'

export default {
  name: 'SAutoComplete',
  components: {
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Object],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },

    rules: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    endpoint: {
      type: String,
      required: true,
      default: '',
    },
    valueField: {
      type: String,
      required: true,
      default: 'uuid',
    },
    textField: {
      type: String,
      required: true,
      default: 'uuid',
    },
    noOptionMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputValue: '',
      selected: null,
      loading: false,
      required,
      options: [],
    }
  },
  watch: {
    value(data) {
      this.inputValue = data
    },
    inputValue(val) {
      this.$emit('input', val)
      this.$emit('onChange', val)
    },
  },
  methods: {

    async fetchOptions(search, loading) {
      loading(true)
      this.$http.showLoader = false
      const data = await this.resourceSearch(loading, this.endpoint + search, this, this.valueField, this.textField)
      this.$http.showLoader = true
      this.options = data
      return data
    },
    resourceSearch: debounce((loading, search, vm, valueField, textField) => {
      // eslint-disable-next-line no-invalid-this
      vm.$http.showLoader = false
      vm.$http.get(search).then(res => {
        const itens = []
        const response = res.data || res
        for (const data of response) {
          itens.push({
            value: data[valueField],
            text: data[textField],
          })
        }
        vm.options = itens
        loading(false)
        vm.$http.showLoader = true
      })
    }, 350),
  },

}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

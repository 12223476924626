import Vue from 'vue'
import moment from 'moment-timezone'

class Sync {
  async execute(silently = false, onlyDownload = false, answerUuid = null) {
    if (!navigator.onLine) {
      window.$notify.error('Não foi possível sincronizar os dados. Não há conexão com a internet.')
      return
    }
    if (!onlyDownload) {
      await this.uploadInformations(silently)
    }
    await this.downloadInformations(answerUuid, onlyDownload)
    if (!silently) {
      window.$notify.success('Sincronização concluída.')
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async uploadInformations(silently) {
    const objectKeys = Object.keys(localStorage)
    objectKeys.map(async key => {
      if (key.startsWith('form_answer_')) {
        const data = JSON.parse(localStorage.getItem(key))
        const response = await window.http.post('form-answers', data)
        if (response.error && !silently) {
          window.$notify.error(response.error_message)
        }
        localStorage.removeItem(key)
      }
    })

    objectKeys.map(async key => {
      if (key.substring(0, 7) === 'answer_') {
        const uuidFormAnswer = key.substring(7)
        const data = {
          status: 'answered',
          answers: JSON.parse(localStorage.getItem(key)),
        }
        await window.http.post(`form-answer-questions/${uuidFormAnswer}`, data)
      }
    })
  }

  async downloadInformations(answerUuid = null, onlyDownload = false) {
    const unities = await window.http.get('unities')
    localStorage.setItem('therapeutic_centers', JSON.stringify(unities))

    const schedules = await window.http.get('mine/schedules?search=status:waiting')
    localStorage.setItem('schedules', JSON.stringify(schedules))

    let url = 'forms/sync'
    if (answerUuid != null && onlyDownload) {
      url += `?answer_uuid=${answerUuid}`
      this.clearAnswers()
    }
    await window.http.get(url).then(response => {
      localStorage.setItem('form', JSON.stringify(response.form.content))
      response.answers_user.map(answerUser => {
        const theResponses = []
        answerUser.answers.map(answer => {
          response.form.content.groups.map(group => {
            group.subgroups.map(subgroup => {
              subgroup.questions.map(question => {
                if (question.uuid === answer.question_uuid) {
                  theResponses.push({
                    question_answer: answer.answer,
                    question_name_input: `field_${answer.question_uuid}`,
                    question_uuid: answer.question_uuid,
                    question_type_form: question.type_form,
                    question_required: question.required,
                  })
                }
              })
            })
          })
        })
        localStorage.setItem(`answer_${answerUser.uuid}`, JSON.stringify(theResponses))
      })
      localStorage.setItem('last_sync', moment())
    })
  }

  clearAnswers() {
    const objectKeys = Object.keys(localStorage)
    objectKeys.map(async key => {
      if (key.startsWith('form_answer_') || key.startsWith('answer_')) {
        localStorage.removeItem(key)
      }
    })
  }

  get lastSync() {
    const date = localStorage.getItem('last_sync')
    if (date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    }
    return ''
  }
}

const sync = new Sync()
Vue.prototype.$sync = sync
export default Sync

<template>
  <b-form-group
    :label="label"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="label"
      :vid="name"
    >
      <b-form-radio
        v-for="(option, index) in options"
        :key="index"
        v-model="model"
        :name="name"
        :value="option.value"
        :disabled="disabled"
        class="mt-1"
        :class="{'i2-form-other': isOther(option.text)}"
        @change="onInput(isOther(option.text))"
      >
        <div style="display: flex" class="align-content-center">
          <span class="mr-1">{{ option.text }}</span>
          <b-form-input v-if="isOther(option.text)" v-model="othervalue" style="width: 60%"
                        @change="onInput(isOther(option.text))"
          />
        </div>
      </b-form-radio>

    </validation-provider>
  </b-form-group>

</template>

<script>

import { BFormGroup, BFormRadio } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'I2FormRadio',
  components: {
    BFormGroup,
    BFormRadio,
    ValidationProvider,
  },
  props: {
    options: {
      type: Array,
      default: [],
    },
    value: {
      type: [String, Number, Object, Array],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    errors: [],
    model: null,
    othervalue: '',
  }),
  watch: {
    value: {
      handler(value) {
        if (typeof (value) === 'object') {
          this.model = value.value
          this.othervalue = value.description || ''
          return
        }
        this.model = value
      },
      deep: true,
    },
  },
  created() {
    if (typeof (this.value) === 'object') {
      this.model = this.value.value
      this.othervalue = this.value.description || ''
      return
    }
    this.model = this.value
  },
  methods: {
    onInput(isOther) {
      let data = this.model
      if (isOther) {
        data = {
          value: this.model,
          description: this.othervalue,
        }
      }

      this.$emit('input', data)
    },
    isOther(text) {
      const regex = /\b(outro|outros|outras|outra)\b/gmi
      return regex.exec(text) !== null
    },
  },

}
</script>

<style >
.i2-form-other .custom-control-label {
  width: 100%!important;
}
</style>

<template>
  <b-card no-body class="p-1" md="12">
    <b-row>
      <b-col md="8">
        <app-breadcrumb />
      </b-col>
      <b-col
        md="4"
        class="text-right"
      >
        <slot/>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb'

export default {
  name: 'PageHeader',
  components: { AppBreadcrumb },
  props: ['name'],
  data: () => ({}),
  mounted() {
  },
  methods: {},
}
</script>

<style scoped>

</style>

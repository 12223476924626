const station = [
  {
    path: '/stations',
    name: 'stations.list',
    component: () => import('@/views/station/StationList.vue'),
    meta: {
      pageTitle: 'Ativos',
      breadcrumb: [
        {
          text: 'Ativos',
          active: true,
        },
      ],
    },
  },
]

export default station

import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue'
import '@/libs/toastification'

class Notify {
  $vm = Vue

  success(text, icon = null) {
    this.$vm.$toast({
      component: ToastificationContent,
      props: {
        title: text,
        icon: icon || 'EditIcon',
        variant: 'success',
      },
    })
  }

  error(text, icon) {
    this.$vm.$toast({
      component: ToastificationContent,
      props: {
        title: text,
        icon: icon || 'EditIcon',
        variant: 'danger',
      },
    })
  }
}

Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
const notify = new Notify()
Vue.prototype.$notify = notify
window.$notify = notify
export default Notify

import Vue from 'vue'

class Alertfy {
    $vm = Vue

    confirm(
      text,
      title = '',
      confirmButtonText = 'Ok',
      cancelButtonText = 'Cancelar',
      onConfirmCallback = null,
      onCancelCallback = null,
    ) {
      this.$vm.swal.fire({
        title: title || null,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
      }).then(result => {
        if (result.isConfirmed) {
          if (onConfirmCallback) {
            onConfirmCallback()
          }
        } else if (onCancelCallback) {
          onCancelCallback()
        }
      })
    }

    success(
      text,
      title = '',
      confirmButtonText = 'Ok',
      isHtml = false,
    ) {
      const params = {
        title: title || null,
        icon: 'success',
        confirmButtonText,
      }
      if (text && !isHtml) {
        params.text = text
      }
      if (isHtml) {
        params.html = text
      }
      this.$vm.swal.fire(params)
    }
}

const alertfy = new Alertfy()
Vue.prototype.$alertfy = alertfy
export default Alertfy

const location = [
  {
    path: '/locations',
    name: 'locations.list',
    meta: {
      pageTitle: 'Cadastro de Locais',
      breadcrumb: [
        {
          text: 'Cadastro de locais',
          active: true,
        },
      ],
    },

    component: () => import('@/views/location/LocationList.vue'),
  },
]

export default location

const users = [
  {
    path: '/users',
    name: 'users.list',
    component: () => import('@/views/users/UsersList.vue'),
    meta: {
      pageTitle: 'Usuários',
      breadcrumb: [
        {
          text: 'Lista',
          active: true,
        },
      ],
    },
  },
  {
    path: '/my-account',
    name: 'my-account',
    component: () => import('@/views/users/account-settings/AccountSettings.vue'),
    meta: {
      pageTitle: 'Minha conta',
    },
  },
]

export default users

<template>
  <b-form-group
    :label="label"
    :label-for="name"
  >
    <validation-provider
      #default="{ errors }"
      :name="label"
      :vid="name"
    >
      <b-form-checkbox-group
        :id="'checkbox-group-' + name"
        v-model="model"
        stacked
        :name="name"
        class="mb-1"
        :disabled="disabled"
      >
        <b-form-checkbox v-for="(option, index) of options"
                         :key="index"
                         class="mt-1"
                         :value="option.value"
                         :class="{'i2-form-other': isOther(option.text)}"
                         @change="onChange(option.value, isOther(option.text))"
        >
          <div style="display: flex" class="align-content-center">
            <span class="mr-1">{{ option.text }}</span>
            <b-form-input v-if="isOther(option.text)" v-model="otherValue" style="width: 60%"
                          @change="onChange(option.value, isOther(option.text))"
            />
          </div>
        </b-form-checkbox></b-form-checkbox-group>
    </validation-provider>
  </b-form-group>

</template>

<script>

import { BFormGroup, BFormCheckboxGroup, BFormCheckbox } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'I2FormCheckbox',
  components: {
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    ValidationProvider,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: [],
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    selected: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    errors: [],
    model: [],
    otherValue: '',
    isOtherOption: '',
  }),
  watch: {
    selected: {
      handler(value) {
        const items = value.map(item => {
          if (typeof (this.value) === 'object') {
            if (item.description !== '') {
              this.otherValue = item.description
            }
            return item.value
          }
          return item
        })
        this.model = items
      },
      deep: true,
    },
  },
  created() {
    const items = this.selected.map(item => {
      if (typeof (item) === 'object') {
        if (item.description !== '') {
          this.otherValue = item.description
        }
        return item.value
      }
      return item
    })
    this.model = items
    const otherOption = this.options.find(item => this.isOther(item.text))
    if (otherOption) {
      this.isOtherOption = otherOption.value
    }
  },
  methods: {

    onChange() {
      const data = this.model.map(item => ({
        value: item,
        description: item === this.isOtherOption ? this.otherValue : '',
      }))
      if (data.length) {
        this.$emit('input', data)
        return
      }
      this.$emit('input', [])
    },
    isOther(text) {
      const regex = /\b(outro|outros|outras|outra)\b/gmi
      return regex.exec(text) !== null
    },
  },

}
</script>

<style scoped>

</style>
